export default {
  name: 'TopBanner',
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
  },
  props: ['topBanner'],
  data() {
    return {
      slide: 0,
      slides: ['assets/img/middle_banner_01.jpg', 'assets/img/middle_banner_02.jpg', 'assets/img/middle_banner_03.jpg', 'assets/img/middle_banner_04.jpg'],
      myOptions: {
        autoplay: true,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        autoplaySpeed: 4000,
        rtl: true,
        dots: false,
        speed: 2000,
        navButtons: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              autoplay: false,
              navButtons: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              autoplay: false,
              navButtons: false,
            },
          },
        ],
      },
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.topMain) {
        this.$refs.topMain.reload();
      }
    }, 1500);
  },
};
