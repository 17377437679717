<template>
  <section class="top-banner mb-1">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <Agile :options="myOptions" class="owl-carousel owl-theme prices-of-flight theme-slider ltr" ref="topMain">
            <div class="slide" v-for="(image, index) in topBanner" :key="index">
                <div class="img-holder" v-if="!!image.caption">
                  <img :src="image.image" :alt="image.caption" class="card-img-top img-fluid linkon"/>
                  <a :href="image.href">
                    <div class="caption">
                      {{ image.caption }}
                    </div>
                  </a>
                </div>
                <div class="img-holder" v-else>
                  <img :src="image.image" alt="img-holder" class="card-img-top img-fluid"/>
                </div>
            </div>

            <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
            <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
          </Agile>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TemplateTopBanner from './TemplateTopBanner';

export default {
  name: 'TopBannerTheme0',
  mixins: [TemplateTopBanner],
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
  },
};
</script>

<style scoped>
.agile__slide {
  padding: 0 10px;
}
.img-holder {
  position: relative;
  overflow: hidden;
}
.img-holder .caption {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  background: white;
  text-align: center;
  width: fit-content;
  transition: 0.5s ease-out;
  cursor: pointer;
}
.img-holder:hover .caption {
  top: calc(50% - 20px);
}
.img-holder:hover img.linkon {
  filter: brightness(0.7);
}
.img-holder:hover .caption {
  box-shadow: #ddf1f9 0px 0px 50px;
}
@media (max-width: 768px) {
  .owl-carousel.prices-of-flight .img-holder {
    height: auto;
  }
}
</style>
<style>

.top-banner .agile__nav-button {
  top: calc(50% - 20px);
}
</style>
